@import "core";
.page.layout-simple {
	.layout-simple-header {
		font-size: 3.2rem;
		margin-bottom: 2rem;
		color: $color-primary;
	}

	.layout-simple-wrap {
		padding: 2rem 0;
	}
}