@import "core";
.section.footer {	
	color: $color-white;
	position: relative;
	z-index: 1;
	overflow: hidden;

	&.plain {
		.footer-bottom {
			background-color: $color-red;
		}
	}

	&:not(.plain) {
		@include media {
			padding-bottom: calc(7.7rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom, 0)));
		}
	}

	.footer-top {
		background-color: $color-red;
		position: relative;
		z-index: 1;

		@include media {
			overflow: hidden;
		}
	}

		.top-bg {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			pointer-events: none;
			
			&:not(.loading) {
				opacity: .4;
			}
		}

		.top-wrap {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			position: relative;
			z-index: 2;
			padding: 7.7rem 0 5rem;
			
			@include media {
				display: block;
				padding: 7.7rem 0 11rem;
			}
		}

		.top-section {
			+ .top-section {
				@include media {
					margin-top: 4rem;
				}

				@include mediaMin {
					margin-left: 6.2rem;
				}
			}
		}	

			.section-title {
				@include font-bold;
				display: block;
				font-size: 2.4rem;
			}

			.section-text {
				font-size: 1.6rem;
				line-height: 1.8rem;
				color: rgba($color-white, .7);
				margin-top: 1.9rem;
			}

		.top-image {
			position: absolute;
			bottom: -8rem;
			right: 0;
			width: 41.4rem;
			height: 39.8rem;
			z-index: 1;

			@include media {
				width: 24.1rem;
				height: 26.8rem;
				right: -4.8rem;
			}
		}

	.footer-bottom {
		@include font-medium;
		background-color: $color-red-light;
		font-size: 1.6rem;
		position: relative;
		z-index: 2;

		@include media {
			padding: 2.3rem 0 3.3rem;
			text-align: center;
			font-size: 1.4rem;
		}
		
		.bottom-wrap {
			@include mediaMin {
				height: 8rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	.footer-social {
		display: flex;
		justify-content: flex-start;
		margin-top: 4.5rem;

		a {
			font-size: 2.5rem;

			+ a {
				margin-left: 3rem;
			}
		}
	}

	.footer-whatsapplink {
		@include font-medium;
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 1.6rem;
		margin-top: 4.5rem;

		.icon {
			font-size: 2.5rem;
			margin-right: 1rem;
		}
	}

	.footer-nav {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@include media {
			margin-bottom: 2rem;
			font-size: 1.6rem;
			flex-wrap: wrap;
			justify-content: center;
			margin-right: -1rem;
			width: calc(100% + 2.5rem);
		}
	}

		.nav-item {
			@include mediaMin {
				+ .nav-item {
					&:before {
						content: '|';
						margin: 0 .5rem;
	
						@include media {
							margin: 0 1rem;
						}
					}
				}
			}

			@include media {
				margin-right: 2.5rem;
				margin-bottom: 1rem;
			}
		}
}