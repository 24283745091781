@import "core";
.section.header {
	position: relative;
	z-index: 3;
	background-color: $color-primary;
	color: $color-white;
	padding: 2.7rem 0;

	&.no-bg {
		background-color: transparent;
	}

	@include media {
		padding: calc(1.6rem + var(--safe-area-insets-top, env(safe-area-inset-top, 1rem))) 0 1.8rem;
	}

	.header-wrap {
		display: flex;
		align-items: center;
	}

	.header-logo {
		width: 4.5rem;
		height: 6.3rem;
		margin-right: 5.5rem;

		@include media {
			width: 3.3rem;
			height: 4.6rem;
			margin-right: 3rem;
		}
	}

		.logo-image {
			width: 100%;
			height: 100%;
		}

	.header-locationselector {
		background-color: $color-red-dark;
		color: $color-white;
		position: relative;
		width: 19.4rem;

		@include media {
			width: auto;
			flex: 1 0 0;
		}

		&.active {
			.locationselector-list {
				display: block;
			}

			.locationselector-btn {
				border-bottom-color: transparent;
			}
		}

		.locationselector-btn {
			@include font-bold;
			font-size: 1.6rem;
			display: flex;
			align-items: center;
			height: 4rem;
			padding: 0 1.6rem;
			width: 100%;
			border: .1rem solid $color-white;
			border-radius: $radius-small;
			background-color: $color-primary;

			@include media {
				padding: 0 1rem;
			}
		}
		
			.btn-pinicon {
				font-size: 1.5rem;
				margin-right: .9rem;
			}

			.btn-branchname {
				flex: 1 0 0;
			}

			.btn-switchicon {
				font-size: 1rem;
				margin-right: .4rem;
			}

			.btn-switchtext {
				font-size: 1.4rem;
			}
	}

	.header-nav {
		@include font-bold;
		margin-left: auto;
		display: flex;
		align-items: center;
		font-size: 1.4rem;

		> .nav-link {
			display: flex;
			align-items: center;
			
			+ .nav-link {
				margin-left: 3.1rem;
			}

			.link-icon {
				font-size: 1.3rem;
				margin-right: .5rem;
			}
		}
	}	

	.header-notificationbtn {
		font-size: 2rem;
		color: $color-white;
		margin-left: 1.8rem;
		position: relative;

		&.highlight {
			.notificationbtn-count {
				opacity: 1;
			}
		}
	}

		.notificationbtn-count {
			@include font-bold;
			pointer-events: none;
			position: absolute;
			bottom: 70%;
			left: 60%;
			background-color: $color-white;
			color: $color-primary;
			opacity: 0;
			font-size: 1rem;
			box-shadow: $shadow-big;
			border-radius: 50%;
			width: 1.4rem;
			height: 1.4rem;
			text-align: center;
			line-height: 1.2rem;
			border: .1rem solid $color-primary;
		}

	.header-searchbtn {
		font-size: 2rem;
		color: $color-white;
		margin-left: 1.8rem;
	}

	.header-menubtn {
		font-size: 2rem;
		color: $color-white;
		margin-left: 2.2rem;
	}
}